import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchDeviceWallboxAccountChargingEvents, fetchDeviceWallboxChargingEventsByDeviceSerial } from '../../../actions/device.action';
import { tr, trans } from '../../../translation/translations';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';
import TableFooter from '../table-footer/TableFooter';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { IconTablesMore } from '../icons/IconsTables';
import getLocalStorageItem from '../../../functions/getLocalStorageItem';


interface DashboardChargingEventsProps {
  type: string,
  perPage: number,
  id?: string,
  device: InterfaceReducerDevice,
  fetchDeviceWallboxAccountChargingEvents: (options: { limit: number, skip: number }) => Promise<void>,
  fetchDeviceWallboxChargingEventsByDeviceSerial: (options: { device_id: string, limit: number, skip: number }) => Promise<void>,
}


interface DashboardChargingEventsState {
  device_wallbox_charging_events: Array<any>,
  device_wallbox_charging_events_count: number,
  currentPage: number,
  perPage: number,
}


export class DashboardChargingEvents extends React.Component<DashboardChargingEventsProps, DashboardChargingEventsState> {


  state = {
    device_wallbox_charging_events: [],
    device_wallbox_charging_events_count: 0,
    perPage: this.props.perPage,
    currentPage: 0,
  }


  componentDidMount(): void {
    this.getWallboxChargingEvents();
  }


  getWallboxChargingEvents = async () => {
    const { perPage, currentPage } = this.state;
    this.reloadPage({ perPage, currentPage });
  }


  updatePagination = (e: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({ perPage: parseInt(e.target.value), currentPage: 0  });
    this.reloadPage({ perPage: parseInt(e.target.value), currentPage: 0  });
  }


  prevPage = (prevPage: number) => {
    this.setState({ currentPage: prevPage });
    this.reloadPage({ currentPage: prevPage, perPage: this.state.perPage });
  }


  nextPage = (nextPage: number) => {
    this.setState({ currentPage: nextPage });
    this.reloadPage({ currentPage: nextPage, perPage: this.state.perPage });
  }


  reloadPage = async (options: { perPage: number, currentPage: number }) => {
    // get wallbox charging events for ACCOUNT
    if(this.props.type === 'account') {
      await this.props.fetchDeviceWallboxAccountChargingEvents({ limit: options.perPage, skip: options.perPage * options.currentPage });
      if(this.props.device.status === fetchStates.success) {
        const { device_wallbox_account_charging_events, device_wallbox_account_charging_events_count } = this.props.device;
        this.setState({ device_wallbox_charging_events: device_wallbox_account_charging_events, device_wallbox_charging_events_count: device_wallbox_account_charging_events_count });
      }
    }
    // get wallbox charging events for DEVICE
    if(this.props.type === 'device' && this.props.id) {
      await this.props.fetchDeviceWallboxChargingEventsByDeviceSerial({ device_id: this.props.id, limit: options.perPage, skip: options.perPage * options.currentPage });
      if(this.props.device.status === fetchStates.success) {
        const { device_wallbox_charging_events, device_wallbox_charging_events_count } = this.props.device;
        this.setState({ device_wallbox_charging_events, device_wallbox_charging_events_count });
      }
    }
  }
  

  render() {

    const { device_wallbox_charging_events, device_wallbox_charging_events_count, perPage, currentPage } = this.state;

    return (
      <>
        <div className="table">
          <div className="row header">
            <div className="cell">ID</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.deviceSerialNo) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.chargingStarted) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.chargingEnded) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.chargingTime) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.energyConsumed) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.totalCost) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.eventType) }</div>
            <div className="cell">{ tr(trans.partialDashboardChargingEvents.tableHeader.error) }</div>
            <div className="cell cell-button"></div>
          </div>
          { device_wallbox_charging_events && device_wallbox_charging_events.map((event: any) => {
            return (
              <div className="row" key={ event.event_id }>
                <div className="cell" data-title="ID">
                  { event.event_id }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.deviceSerialNo) }>
                  { event.device_serial }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.chargingStarted) }>
                  { moment(event.event_start_tst).format('DD MMM - HH:mm') }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.chargingEnded) }>
                  { moment(event.event_end_tst).format('DD MMM - HH:mm') }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.chargingTime) }>
                  { moment.utc(event.event_charging_time_s*1000).format('HH:mm:ss') }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.energyConsumed) }>
                  { event.event_energy_consumed_kwh } kWh
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.totalCost) }>
                  { (event.event_energy_consumed_kwh * getLocalStorageItem('account_energy_cost')).toFixed(2) } { getLocalStorageItem('account_currency') }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.eventType) }>
                  { event.event_type }
                </div>
                <div className="cell" data-title={ tr(trans.partialDashboardChargingEvents.tableHeader.error) }>
                  { event.event_error }
                </div>
                <div className="cell">
                  <Link className="table-icon" to={`/charging-event/${ event.event_id }`}>
                    <IconTablesMore color="#666" size={24} />
                  </Link>
                </div>
              </div>
            );
          })
        }

        </div>
        <TableFooter 
          currentPage={ currentPage }
          perPage={ perPage }
          itemsCount={ device_wallbox_charging_events_count }
          prevPage={() => this.prevPage(currentPage - 1)}
          nextPage={() => this.nextPage(currentPage + 1)}
          updatePagination={this.updatePagination}
        />
      </>
    );
  }
};


export default connect(
  ({ device }) => ({ device }),
  { fetchDeviceWallboxAccountChargingEvents, fetchDeviceWallboxChargingEventsByDeviceSerial }
)(DashboardChargingEvents);