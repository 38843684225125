import React from 'react';
import WithRouter from '../../hoc/router/WithRouter';
import { connect } from 'react-redux';
import { logout } from '../../../actions/auth.action';
import './navigation.css';
import { IconLogoDarkDesktop } from '../icons/IconsLogo';
import { APP_VERSION } from '../../../constants';
import { IconArrowLeft, IconNavDevices, IconNavEvents, IconNavGroups, IconNavHelp, IconNavHome, IconNavLogout, IconNavSettings, IconNavUsers } from '../icons/IconsNavigation';
import { tr, trans } from '../../../translation/translations';
import NavigationItemDesktop from '../navigation-item-desktop/NavigationItemDesktop';
import classnames from 'classnames';
import Authorization from '../authorization/Authorization';


interface NavigationProps {
  router: { location: any, navigate: (to: string) => any },
  logout: () => Promise<void>,
  menuClose: () => void,
  menuOpened: boolean,
}


export class Navigation extends React.Component<NavigationProps> {

  handleLogout = async () => {
    await this.props.logout();
    await this.props.router.navigate('/login');
  }

  render() {

    return (
      <nav className={classnames('shared shared__navigation', { 'open': this.props.menuOpened })}>


        <div className="navigation__logo-warpper">
          <div className="navigation__logo">
            <IconLogoDarkDesktop width={160} height={80} />
          </div>
          <div className="navigation__close">
            <span onClick={ this.props.menuClose }>
              <IconArrowLeft size={20} color="#fff" />
            </span>
          </div>
        </div>


        <div className="navigation__main-wrapper">

          <NavigationItemDesktop 
            to="/" 
            title={ tr(trans.navigation.dashboard) } 
            icon={ <IconNavHome color="#000" size={24} /> } 
          />


          <Authorization requiredAuthorization="manage_events">
            <NavigationItemDesktop 
              to="/events" 
              title={ tr(trans.navigation.events) }
              icon={ <IconNavEvents color="#000" size={24} /> } 
            />
          </Authorization>


          <Authorization requiredAuthorization="manage_devices">
            <NavigationItemDesktop 
              to="/devices" 
              title={ tr(trans.navigation.devices) }
              icon={ <IconNavDevices color="#000" size={24} /> } 
            />
          </Authorization>


          <Authorization requiredAuthorization="manage_groups">
            <NavigationItemDesktop 
              to="/groups" 
              title={ tr(trans.navigation.groups) }
              icon={ <IconNavGroups color="#000" size={24} /> } 
            />
          </Authorization>

          <Authorization requiredAuthorization="manage_devices_users">
            <NavigationItemDesktop 
              to="/devices-users" 
              title={ tr(trans.navigation.users) }
              icon={ <IconNavUsers color="#000" size={24} /> } 
            />
          </Authorization>


        </div>



        <div className="navigation__tools-wrapper">

          <NavigationItemDesktop 
            to="/settings" 
            title={ tr(trans.navigation.settings) }
            icon={ <IconNavSettings color="#000" size={24} /> } 
          />


          <NavigationItemDesktop 
            to="https://help.acenergy.com.pl" 
            title={ tr(trans.navigation.help) }
            icon={ <IconNavHelp color="#000" size={24} /> } 
          />


          <NavigationItemDesktop 
            to="/logout" 
            title={ tr(trans.navigation.logout) }
            icon={ <IconNavLogout color="#000" size={24} /> }
            handleClick={this.handleLogout}
          />

        </div>


        <div className="navigation__version">
          { APP_VERSION }
        </div>


      </nav>
    );
  }

};


export default WithRouter(connect(
  () => ({}),
  { logout }
)(Navigation));