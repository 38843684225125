import { USER } from '../types/reducer.types';
import fetchStates from '../types/fetchState.types';
import InterfaceReducerUser from '../intefaces/InterfaceReducerUser';


const DEFAULT_USER_STATE: InterfaceReducerUser = { 
  status: '',
  fields: [],
  msg: [],
  devices_users_count: 0,
  devices_users: [],
  devices_user: []
}


const user = (state = DEFAULT_USER_STATE, action: any) => {
  if(action.authorizationHeader) {
    localStorage.setItem('accessToken', action.authorizationHeader);
  }
  switch(action.type) {
    case USER.FETCH:
      return { 
        ...state, 
        status: fetchStates.fetching,
        msg: [],
        fields: [],
      };
    case USER.FETCH_ERROR:
      return { 
        ...state, 
        status: fetchStates.error,
        msg: action.errors,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case USER.FETCH_USER_COUNT_SUCCESS:
      return {
        ...state,
        userd_count: action.devices_users_count,
        status: fetchStates.success,
      };
    case USER.FETCH_USER_SUCCESS:
      return {
        ...state,
        devices_users: action.devices_users,
        devices_users_count: action.devices_users_count,
        status: fetchStates.success,
      };
    case USER.FETCH_USER_BY_ID_SUCCESS:
      return {
        ...state,
        user: action.user,
        status: fetchStates.success,
      };
    case USER.FETCH_INSERT_USER_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case USER.FETCH_UPDATE_USER_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success,
        fields: action.errors && action.errors.map((error: {path: string}) => error.path !== undefined && error.path.toString()),
      };
    case USER.FETCH_DELETE_USER_BY_ID_SUCCESS:
      return {
        ...state,
        status: fetchStates.success,
        msg: action.success
      };
    default:
      return state;
  }
};


export default user;