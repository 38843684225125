const APP_VERSION = '0.5.0';
const COOKIES_LANGUAGE_EXPIRE_DAYS = 100000;
const COOKIES_LANGUAGE_DEFAULT = 'en';
const GMAP_API_KEY = 'AIzaSyDQgav3PwcZSeUhCwQkwLfafphYwE3Zs4o';
const USER_AUTHORIZED = {
  'manage_devices_users': false,
  'manage_application_users': false,
  'manage_events': false,
  'manage_groups': false,
  'manage_devices': false,
  'manage_energy_prices': false
};


if (process.env.REACT_APP_WS_PROTOCOL === undefined) { console.error(`[ERROR]: environmental variable REACT_APP_WS_PROTOCOL is not defined`); }
if (process.env.REACT_APP_WS_DOMAIN === undefined) { console.error(`[ERROR]: environmental variable REACT_APP_WS_DOMAIN is not defined`); }
if (process.env.REACT_APP_WS_PORT_DIST === undefined) { console.error(`[ERROR]: environmental variable REACT_APP_WS_PORT_DIST is not defined`); }


const REACT_APP_WS_PROTOCOL = process.env.REACT_APP_WS_PROTOCOL;
const REACT_APP_WS_DOMAIN = process.env.REACT_APP_WS_DOMAIN;
const REACT_APP_WS_PORT_DIST = process.env.REACT_APP_WS_PORT_DIST;


export { 
  APP_VERSION,
  COOKIES_LANGUAGE_EXPIRE_DAYS,
  COOKIES_LANGUAGE_DEFAULT,
  GMAP_API_KEY,
  USER_AUTHORIZED,
  REACT_APP_WS_PROTOCOL,
  REACT_APP_WS_DOMAIN,
  REACT_APP_WS_PORT_DIST
};