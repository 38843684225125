import React from 'react';
import Box from '../box/Box';
import BoxTitlebar from '../box-titlebar/BoxTitlebar';
import ChargingEventsTable from '../charging-events-table/ChargingEventsTable';
import { tr, trans } from '../../../translation/translations';


const DashboardChargingEvents: React.FC = () => {
  return (
    <Box>
      <BoxTitlebar title={ tr(trans.partialDashboardChargingEvents.partialTitle) } />
      <ChargingEventsTable perPage={ 5 } type="account" />
    </Box>
  );
};


export default DashboardChargingEvents;