import { PERSON, PRELOADER } from '../types/reducer.types';
import { errorNotification, successNotification } from '../functions/notifications';


const fetchUser = (options: { endpoint: string, options: any, SUCCESS_TYPE: string }) => (dispatch:any) => {

  dispatch({ type: PERSON.FETCH });
  let authorizationHeader: any = [];
  dispatch({ type: PRELOADER.SHOW_PRELOADER });
  return fetch(`/api/person/${options.endpoint}`, options.options)
          .then(response => {
              authorizationHeader = response.headers.get('Authorization');
              return response.json();
            }
          )
          .then(json => {
            dispatch({ type: PRELOADER.HIDE_PRELOADER });
            if (json.errors !== undefined) {
              dispatch({ type: PERSON.FETCH_ERROR, ...json, authorizationHeader });
              json && json.errors && json.errors.map((item: {msg: string}) => {
                if(item.msg) {
                    errorNotification(item.msg);
                  }
                  return null;
              });
            } else {            
              dispatch({ type: options.SUCCESS_TYPE, ...json, authorizationHeader });
              json && json.success && json.success.map((item: {msg: string}) => {
                if(item.msg) {
                  successNotification(item.msg);
                }
                return null
              });
            }
          })
          .catch(error => dispatch({
            type: PERSON.FETCH_ERROR, errors: [{ msg: error }], authorizationHeader
          }));
  }


  export const fetchAppUsersCount = () => fetchUser({
    endpoint: `count`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: PERSON.FETCH_PERSON_COUNT_SUCCESS
  });


  export const fetchAppUsers = (options: { limit: number, skip:number }) => fetchUser({
    endpoint: `?limit=${options.limit}&skip=${options.skip}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: PERSON.FETCH_PERSON_SUCCESS
  });


  export const fetchAppUserById = (user_id: string) => fetchUser({
    endpoint: `${user_id}`,
    options: { 
      headers: { 
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
      credentials: 'include' 
    },
    SUCCESS_TYPE: PERSON.FETCH_PERSON_BY_ID_SUCCESS
  });


  export const addAppUser = (user: any) => fetchUser({
    endpoint: ``,
    options: {
      method: 'POST',
      body: JSON.stringify({ user }),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: PERSON.FETCH_INSERT_PERSON_SUCCESS
  });


  export const updateAppUserById = (user: any) => fetchUser({
    endpoint: `${user.user_id}`,
    options: {
      method: 'PUT',
      body: JSON.stringify({ user }),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: PERSON.FETCH_UPDATE_PERSON_BY_ID_SUCCESS
  });


  export const deleteAppUserById = (user: any) => fetchUser({
    endpoint: `${user.user_id}`,
    options: {
      method: 'DELETE',
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('accessToken')}`
      },
    },
    SUCCESS_TYPE: PERSON.FETCH_DELETE_PERSON_BY_ID_SUCCESS
  });