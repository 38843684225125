import React from 'react';
import { connect } from 'react-redux';
import fetchStates from '../../../types/fetchState.types';
import { fetchDeviceWallboxById, fetchDeviceWallboxChargingEventsByDeviceSerial } from '../../../actions/device.action';

import WithAuthentication from '../../hoc/auth/WithAuthentication';
import WithRouter from '../../hoc/router/WithRouter';
import WithWs from '../../hoc/ws/WithWs';

import TemplatePageWithNavigation from '../../shared/template-page-navigation/TemplatePageWithNavigation';
import InterfaceReducerDevice from '../../../intefaces/InterfaceReducerDevice';
import Box from '../../shared/box/Box';
import WithPreloader from '../../hoc/preloader/WithPreloader';
import { tr, trans } from '../../../translation/translations';
import Map from '../../shared/map/Map';
import BoxTitlebar from '../../shared/box-titlebar/BoxTitlebar';
import ChargingEventsTable from '../../shared/charging-events-table/ChargingEventsTable';




interface DeviceWallboxProps {
  router: { location: string, navigate: (to: any) => any, params: { device_id: string } },
  device: InterfaceReducerDevice,
  wsConnected: boolean,
  wsData: any,
  dataCommandEmit: any
  fetchDeviceWallboxById: (device_id: string) => Promise<void>,
  fetchDeviceWallboxChargingEventsByDeviceSerial: (options: { device_id: string, limit: number, skip: number }) => Promise<void>,
}


interface DeviceWallboxState {
  device_wallbox: {
    device_title: string,
    device_serial: string,
    group_id: string,
    device_data: any
  },
  requestedDeviceData: boolean,
  device_wallbox_charging_events: Array<any>,
  device_wallbox_charging_events_count: number,
  currentPage: number,
  perPage: number,
}


export class DeviceWallbox extends React.Component<DeviceWallboxProps, DeviceWallboxState> {


  state = {
    device_wallbox: {
      device_title: '',
      device_serial: '',
      device_id: '',
      group_id: '',
      device_lng: '',
      device_lat: '',
      device_data: {
        PWR_out: {
          STch: 0
        },
        SEC: {
          DET: 'N'
        },
      }
    },
    requestedDeviceData: false,
    device_wallbox_charging_events: [],
    device_wallbox_charging_events_count: 0,
    currentPage: 0,
    perPage: 10,
  }


  componentDidUpdate(prevProps:any) {
    // incomming data for device_wallbox from redux state
    if (this.props.device.device_wallbox !== prevProps.device.device_wallbox) {
      const { device_wallbox } = this.props.device;
      this.setState({ device_wallbox: { ...device_wallbox }});
    }
    // fire only after wsConnected
    if(this.props.wsConnected === true) {
      // checking if already requested the data once
      if(this.state.requestedDeviceData === false) {
        this.setState({ requestedDeviceData: true });
        this.getWallboxDeviceById();
        this.dataDeviceRequest(this.state.device_wallbox.device_serial);
      }
    }
  }


  getWallboxDeviceById = async () => {
    await this.props.fetchDeviceWallboxById(this.props.router.params.device_id);
    if(this.props.device.status === fetchStates.success) {
      const { device_wallbox } = this.props.device;
      this.setState({ device_wallbox });
      this.dataDeviceRequest(this.state.device_wallbox.device_serial);
    }
  }


  // WS: requesting data
  dataDeviceRequest = (device_serial: string) => {
    const data = { 
      device_serial,
      ts: Math.floor(Date.now() / 1000),
      message_type: 'device',
      message_command: 'view',
      message_value: [ "DEV_I", "PWR_I", "PWR_in", "PWR_out", "PWR_aux", "SEC", "Ulog", "WiFi", "SLOT", "ERRW", "CS" ] 
    };
    this.props.dataCommandEmit(data);
  }


  // WS: turning the button on/off
  pushDataCommandEmit = (data:any) => {
    this.props.dataCommandEmit(data);
  }


  render() {

    const { device_wallbox } = this.state;

    return (
      <TemplatePageWithNavigation title={ device_wallbox.device_title } buttonBack>

        {
          (device_wallbox.device_lat !== null && device_wallbox.device_lng !== null) ?  (
            <Box>
              <Map zoom={12} positionChargers={[{ ...device_wallbox }]} />
            </Box>
          ) : <></>
        }



        <Box>
          <div>
            <strong>VEHICLE DETECTED:</strong> { device_wallbox.device_data && device_wallbox.device_data.SEC.DET }
          </div>
          <div>
            <strong>CHARGING STATUS:</strong> { device_wallbox.device_data && device_wallbox.device_data.PWR_out.STch }
          </div>   

          <div>
            { 
              device_wallbox.device_data && device_wallbox.device_data.PWR_out.STch === 2 ? (
                <>
                  <div>Device is currently charging</div>
                  <div>
                    <button className="button button--primary button--small" onClick={() => this.pushDataCommandEmit({ "device_serial": device_wallbox.device_serial, "message_type": "device", "message_command": "charge", "message_value": 0 })}>
                      { tr(trans.viewDevicesWallbox.tableButton.stopCharging) }
                    </button> 
                  </div>
                </>
              ) : (
                <>
                  <div>Device is currently NOT charging</div>
                  <div>
                    <button className="button button--primary button--small" onClick={() => this.pushDataCommandEmit({ "device_serial": device_wallbox.device_serial, "message_type": "device", "message_command": "charge", "message_value": 1 })}>
                      { tr(trans.viewDevicesWallbox.tableButton.startCharging) }
                    </button>
                  </div>
                </>
              )
            }
          </div>
        </Box>


        {
          device_wallbox.device_data && (
            <Box>
              <>
                { JSON.stringify(device_wallbox.device_data, null, 2) }
              </>   
            </Box>
          )
        }



        <Box>
          <BoxTitlebar title="Charging events" />
          <ChargingEventsTable perPage={ 10 } type="device" id={ this.props.router.params.device_id } />
        </Box>


      </TemplatePageWithNavigation>
    );
  }
};


export default WithAuthentication(WithWs(WithPreloader(WithRouter(connect(
  ({ device }) => ({ device }),
  { fetchDeviceWallboxById, fetchDeviceWallboxChargingEventsByDeviceSerial }
)(DeviceWallbox)))));